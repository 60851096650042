import React, { Component } from "react";
import { Pressable, View, StyleSheet } from "react-native";
import { Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { Link, withRouter } from "../../routers/routing";
import { footerStyles } from "./FooterStyle";
import { withHooksHOC, getContrastColor, withLocation } from "../../shared/helpers";
import ExternalLink from "../../helpers/link/ExternalLink";

class FooterDesktop extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { theme, location, settings } = this.props;
        const { pathname } = location;
        const customFooterLinks = settings.store.customFooterLinks || [];
        const validCustomFooterLinks = customFooterLinks.filter((link) => link.path && link.label);
        const links = [
            { path: "/impressum", label: "Impressum" },
            { path: "/datenschutz", label: "Datenschutz" },
            { path: "/disclaimer", label: "Disclaimer" },
            { path: "/allgemeinegeschaeftsbedingungen", label: "AGB" },
            ...validCustomFooterLinks.map((link) => ({
                path: link.path,
                label: link.label,
                external: true,
            })),
        ];

        return (
            <React.Fragment>
                <View
                    nativeID="footer-menu"
                    style={[
                        footerStyles.footerDesktopMenu,
                        {
                            backgroundColor: theme.colors.primary,
                        },
                    ]}
                >
                    <View nativeID="footer-links" style={footerStyles.footerDesktopLinks}>
                        {links.map((link, index) => (
                            <Pressable key={link.path}>
                                <Text>
                                    {link.external ? (
                                        <ExternalLink
                                            id={`external-link-${index}`}
                                            url={link.path}
                                            style={{
                                                ...StyleSheet.flatten(footerStyles.footerDesktopLink),
                                                color: getContrastColor(theme.colors.primary),
                                            }}
                                            textStyle={{ color: getContrastColor(theme.colors.primary) }}
                                        >
                                            {link.label}
                                        </ExternalLink>
                                    ) : (
                                        <Link
                                            style={{
                                                ...StyleSheet.flatten(footerStyles.footerDesktopLink),
                                                color: getContrastColor(theme.colors.primary),
                                            }}
                                            to={link.path}
                                        >
                                            {pathname === link.path ? <u>{link.label}</u> : link.label}
                                        </Link>
                                    )}
                                    {index < links.length - 1 && (
                                        <Text
                                            style={[
                                                footerStyles.footerDesktopLinkSeparator,
                                                { color: getContrastColor(theme.colors.primary) },
                                            ]}
                                        >
                                            |
                                        </Text>
                                    )}
                                </Text>
                            </Pressable>
                        ))}
                    </View>
                </View>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings } = state;
    return { settings };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(withTheme(withLocation(FooterDesktop)))));
