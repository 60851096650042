import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    contentContainer: {
        flex: 1,
        padding: 10,
    },
    datePicker: {
        width: 320,
        height: 260,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    mainContainer: {
        flex: 1,
        flexDirection: "row",
    },
    desktopContainerWrapper: {
        // react native doesn't understand: margin: "0 auto",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 0,
        marginBottom: 0,
        maxWidth: 1280,
        paddingTop: 35,
        paddingBottom: 70,
        position: "relative",
        width: "100%",
    },
    screenContainer: {
        flex: 1,
    },
    screenContainerPortal: {
        flex: 1,
        // height: "100vh",
        overflow: "hidden",
    },
    sidebarContainer: {
        width: 250,
    },
    loadingContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "white",
    },
    orderProcessingOverlayContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: 10,
    },
    orderProcessingOverlay: {
        justifyContent: "center",
        alignItems: "center",
        height: 250,
        width: 350,
        padding: 20,
        fontSize: 14,
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "black",
        borderRadius: "13px",
        position: "fixed",
        top: "40%",
    },
    overlayErrorText: {
        marginBottom: 15,
        textAlign: "center",
    },
    overlayErrorIconContainer: {
        padding: 7,
        borderRadius: 20,
    },
    picker: {
        fontFamily: "regular",
        borderWidth: 0,
        minHeight: 40,
        fontSize: 16,
    },
    pickerError: {
        fontSize: 12,
        margin: 5,
    },
    pickerWrapper: {
        borderBottomWidth: 1,
        transform: [{ translateX: 0 }],
        marginBottom: 10,
        width: "100%",
    },
    pickerErrorWrapper: {
        borderBottomWidth: 1,
        marginBottom: 0,
        width: "100%",
        transform: "transform",
    },
    overlayCloseIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 10,
        width: 30,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    backdrop: {
        position: "absolute",
        zIndex: 0,
        width: "100%",
        height: "100%",
    },
    modalContainerStyle: {},
    addressDialogContainer: {
        top: "5vh",
        marginLeft: 0,
        marginRight: 0,
        position: "fixed",
        zIndex: 1,
        maxHeight: "90vh",
        marginHorizontal: 0,
    },
    dialogHeadline: {
        fontFamily: "regular",
        textAlign: "center",
    },
    dialogScrollArea: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        paddingHorizontal: 0,
        marginHorizontal: 0,
    },
    dialogCloseButton: {
        position: "absolute",
        right: 5,
        top: -15,
        zIndex: 15,
        borderRadius: 20,
    },
    dialogButtonContainerHorizontal: {
        width: "100%",
        flexDirection: "column",
    },
    dialogButtonContainerVertical: {
        width: "100%",
        flexDirection: "row",
    },
    profilEditSelect: {
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 5,
    },
    profilEditSelectLabel: {
        fontSize: 20,
        fontWeight: "normal",
        marginBottom: 10,
    },
    profilEditSelectInfoText: {
        fontSize: 14,
        fontWeight: "normal",
        marginBottom: 10,
    },
    // Customer Area
    customerNavigationContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        width: "100%",
    },
    customerNavigationCard: {
        alignItems: "center",
        aspectRatio: 1,
        cursor: "pointer",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    customerNavigationCardContent: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    customerArea: {
        display: "flex",
        flex: 1,
        height: "100%",
        justifyContent: "flex-start",
        width: "100%",
    },
    customerAreaMobile: {
        flexDirection: "column",
    },
    customerAreaDesktop: {
        flexDirection: "row",
    },
    customerNavigationMobile: {
        marginBottom: 15,
        width: "100%",
    },
    customerNavigationDesktop: {
        width: "10%",
    },
    customerContentMobile: {
        width: "100%",
    },
    customerContentDesktop: {
        width: "90%",
    },
});
