import { Formik } from "formik";
import React, { Component } from "react";
import { Dimensions, ScrollView, View, Pressable, StyleSheet, Platform } from "react-native";
import {
    Button,
    Card,
    Checkbox,
    Dialog,
    HelperText,
    IconButton,
    Portal,
    RadioButton,
    Text,
    Title,
    withTheme,
} from "react-native-paper";
import { Link } from "../../routers/routing";
import { connect } from "react-redux";
import * as yup from "yup";
import { Picker } from "@react-native-picker/picker";

import { store } from "../../store/store";
import { createYupSchema, desktopBreakpoint, getContrastColor } from "../../shared/helpers";
import { styles } from "../../shared/styles";
import { getCustomerAddresses } from "../../store/actions/accountActions";
import CustomerCheckoutAddAddress from "./CustomerCheckoutAddAddress";
import { customerDataStyles } from "./CustomerDataStyles";
import CustomInputField from "../../helpers/inputField/CustomInputField";
import CustomDropdown from "../../helpers/inputField/CustomDropdown";
import MissingAddressPopup from "./MissingAddressPopup";
import AddAddressPopup from "./AddAddressPopup";

class CustomerData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            useDifferentBillingAddress: false,
            customerData: null,
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            addresses: [],
            paymentMethod: "",
            showMissingAddressPopup: false,
            showAddAddressPopup: false,
            additionalAddressToEdit: null,
            edit: false,
        };

        this.formElements = this.formElements.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.customerDataForm = React.createRef();
        this.buildInitialValues = this.buildInitialValues.bind(this);
        this.refreshAddresses = this.refreshAddresses.bind(this);
        this.toggleMissingAddressPopup = this.toggleMissingAddressPopup.bind(this);
        this.openAddAddressPopup = this.openAddAddressPopup.bind(this);
        this.toggleAddAddressPopup = this.toggleAddAddressPopup.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
        if (this.props.account.customer) this.fetchCustomerAddresses(this.props.keycloak.token);
    }

    componentDidUpdate(prevProps) {
        if (this.props.account.customer !== prevProps.account.customer) {
            this.refreshAddresses();
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    async fetchCustomerAddresses(token) {
        try {
            const response = await store.dispatch(getCustomerAddresses(token));
            if (response.ok) {
                const addresses = await response.json();
                this.setState({ addresses });
                this.setState({ addressesAreLoading: false });
                this.buildInitialValues();
            } else {
                this.setState({ addressesAreLoading: false });
                console.error("Failed to fetch customer addresses:", response.statusText);
            }
        } catch (error) {
            this.setState({ addressesAreLoading: false });
            console.error("Error fetching customer addresses:", error);
        }
    }

    refreshAddresses() {
        this.fetchCustomerAddresses(this.props.keycloak.token);
    }

    componentWillUnmount() {
        document.body.style.overflow = "scroll";
    }

    toggleAddAddressPopup() {
        this.setState((prevState) => ({
            showAddAddressPopup: !prevState.showAddAddressPopup,
            additionalAddressToEdit: prevState.edit ? null : prevState.additionalAddressToEdit,
            edit: false,
        }));
    }

    openAddAddressPopup() {
        this.setState({ showAddAddressPopup: true });
    }

    toggleMissingAddressPopup() {
        this.setState({ showMissingAddressPopup: !this.state.showMissingAddressPopup });
    }

    formElements(props) {
        return this.props.settings.customerData.fields.map((field, index) => {
            const error = props.errors.hasOwnProperty(field.id) && props.errors[field.id];
            if (!this.props.isRegistration || field.showInRegistration) {
                if (field.type === "text") {
                    const { customer } = this.props.account;
                    const { windowWidth } = this.state;
                    let isBillingAddressAttribute = false;
                    let fieldId = field.id;

                    switch (field.id) {
                        case "firstName":
                            if (customer) props.values[field.id] = customer.firstName;
                            break;
                        case "lastName":
                            if (customer) props.values[field.id] = customer.familyName;
                            break;
                        case "email":
                            if (customer) props.values[field.id] = customer.email;
                            break;
                        case "phoneNumber":
                            if (customer) props.values[field.id] = customer.phoneNumber;
                            break;
                        case "billingTitle":
                        case "billingFirstName":
                        case "billingLastName":
                        case "billingStreet":
                        case "billingHousenumber":
                        case "billingZipCode":
                        case "billingCity":
                            isBillingAddressAttribute = true;
                            fieldId = `billing-${field.id}`;
                            break;
                    }

                    return (
                        ((customer && field.showWhenLoggedIn) ||
                            (!customer && field.showWhenNotLoggedIn && !isBillingAddressAttribute) ||
                            (!customer && isBillingAddressAttribute && props.values["useDifferentBillingAddress"])) && (
                            <View
                                key={field.id}
                                id={field.id}
                                style={
                                    (customerDataStyles.fieldRow,
                                    windowWidth <= desktopBreakpoint
                                        ? customerDataStyles.fieldFormFullRow
                                        : customerDataStyles.fieldFormSplitRow)
                                }
                            >
                                <CustomInputField
                                    key={index}
                                    id={fieldId}
                                    label={field.placeholder}
                                    disabled={field.disabled}
                                    onChange={props.handleChange(field.id)}
                                    value={props.values[field.id] ? props.values[field.id] : ""}
                                    error={props.errors[field.id] ? true : false}
                                    helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                                />
                            </View>
                        )
                    );
                }
                if (field.type === "password") {
                    return (
                        <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                            <CustomInputField
                                key={index}
                                type="password"
                                label={field.placeholder}
                                disabled={field.disabled}
                                onChange={props.handleChange(field.id)}
                                value={props.values[field.id] ? props.values[field.id] : ""}
                                error={props.errors[field.id] ? true : false}
                                helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                            />
                        </View>
                    );
                }

                if (field.type === "textarea") {
                    return (
                        <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                            <CustomInputField
                                key={index}
                                multiline={true}
                                minRows={5}
                                label={field.placeholder}
                                disabled={field.disabled}
                                onChange={props.handleChange(field.id)}
                                value={props.values[field.id] ? props.values[field.id] : ""}
                                error={props.errors[field.id] ? true : false}
                                helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                            />
                        </View>
                    );
                }

                if (field.id === "country") {
                    const { windowWidth } = this.state;
                    const { customer } = this.props.account;
                    const availableCountries = this.props.settings.store.countries || [];
                    const sortedCountries = [...availableCountries].sort((a, b) => a.name.localeCompare(b.name));
                    let isBillingAddressAttribute = false;
                    const isSingleCountry = sortedCountries.length === 1;

                    const defaultCountry = sortedCountries.find((country) => country.isDefault);

                    return (
                        ((customer && field.showWhenLoggedIn) ||
                            (!customer && field.showWhenNotLoggedIn && !isBillingAddressAttribute) ||
                            (!customer && isBillingAddressAttribute && props.values["useDifferentBillingAddress"])) && (
                            <View
                                key={field.id}
                                id={field.id}
                                style={
                                    (customerDataStyles.fieldRow,
                                    windowWidth <= desktopBreakpoint
                                        ? customerDataStyles.fieldFormFullRow
                                        : customerDataStyles.fieldFormSplitRow)
                                }
                            >
                                <CustomDropdown
                                    label="Land"
                                    error={props.errors[field.id] ? true : false}
                                    helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                                    onChange={(value) => props.setFieldValue(field.id, value)}
                                    items={sortedCountries.map((country) => ({
                                        label: country.name,
                                        value: country.countryId,
                                    }))}
                                    value={props.values[field.id] || (defaultCountry ? defaultCountry.countryId : "")}
                                    placeholder="Wählen Sie ein Land"
                                    disabled={isSingleCountry}
                                />
                            </View>
                        )
                    );
                }

                if (field.id === "billingCountry") {
                    const { customer } = this.props.account;
                    let isBillingAddressAttribute = true;
                    const { windowWidth } = this.state;
                    const availableCountries = this.props.settings.store.countries || [];
                    const sortedCountries = [...availableCountries].sort((a, b) => a.name.localeCompare(b.name));

                    const isSingleCountry = sortedCountries.length === 1;

                    return (
                        ((customer && field.showWhenLoggedIn) ||
                            (!customer && field.showWhenNotLoggedIn && !isBillingAddressAttribute) ||
                            (!customer && isBillingAddressAttribute && props.values["useDifferentBillingAddress"])) && (
                            <View
                                key={field.id}
                                id={field.id}
                                style={
                                    (customerDataStyles.fieldRow,
                                    windowWidth <= desktopBreakpoint
                                        ? customerDataStyles.fieldFormFullRow
                                        : customerDataStyles.fieldFormSplitRow)
                                }
                            >
                                <CustomDropdown
                                    label="Land"
                                    error={props.errors[field.id] ? true : false}
                                    helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                                    onChange={(value) => props.setFieldValue(field.id, value)}
                                    items={sortedCountries.map((country) => ({
                                        label: country.name,
                                        value: country.countryId,
                                    }))}
                                    value={props.values[field.id]}
                                    placeholder="Wählen Sie ein Land"
                                    disabled={isSingleCountry}
                                />
                            </View>
                        )
                    );
                }

                if (field.type === "printBillCheckbox") {
                    return (
                        <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                            <Checkbox.Item
                                label="Rechnung drucken"
                                status={props.values[field.id] ? "checked" : "unchecked"}
                                onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                                uncheckedColor={this.props.theme.colors.primary}
                                color={getContrastColor(this.props.theme.colors.primary)}
                                style={[
                                    customerDataStyles.checkbox,
                                    {
                                        backgroundColor: props.values[field.id]
                                            ? this.props.theme.colors.primary
                                            : this.props.theme.colors.surface,
                                        borderColor: this.props.theme.colors.primary,
                                    },
                                ]}
                                labelStyle={{
                                    color: props.values[field.id]
                                        ? getContrastColor(this.props.theme.colors.primary)
                                        : getContrastColor(this.props.theme.colors.surface),
                                }}
                                position="leading"
                                rippleColor="transparent"
                                mode="android"
                            />
                        </View>
                    );
                }
                if (field.id === "acceptTerms") {
                    return (
                        <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                            <Checkbox.Item
                                label="Ich akzeptiere die AGB und Datenschutzbestimmungen"
                                status={props.values[field.id] ? "checked" : "unchecked"}
                                onPress={() => {
                                    props.setFieldValue(field.id, !props.values[field.id]);
                                }}
                                uncheckedColor={this.props.theme.colors.primary}
                                color={getContrastColor(this.props.theme.colors.primary)}
                                style={[
                                    customerDataStyles.checkbox,
                                    {
                                        backgroundColor: props.values[field.id]
                                            ? this.props.theme.colors.primary
                                            : this.props.theme.colors.surface,
                                        borderColor: this.props.theme.colors.primary,
                                    },
                                ]}
                                labelStyle={{
                                    color: props.values[field.id]
                                        ? getContrastColor(this.props.theme.colors.primary)
                                        : getContrastColor(this.props.theme.colors.surface),
                                }}
                                position="leading"
                                rippleColor="transparent"
                                mode="android"
                            />
                            {props.errors[field.id] && (
                                <Text style={[customerDataStyles.helperText, { color: this.props.theme.colors.error }]}>
                                    Bitte akzeptieren Sie die AGB und Datenschutzbestimmungen
                                </Text>
                            )}

                            <Text style={customerDataStyles.helperText}>
                                Unsere Datenschutzbestimmungen / AGB können Sie
                                {Platform.OS === "web" ? (
                                    <a
                                        style={{
                                            ...StyleSheet.flatten(customerDataStyles.customLink),
                                            color: this.props.theme.colors.text,
                                        }}
                                        href="/datenschutz"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        hier
                                    </a>
                                ) : (
                                    <Link
                                        style={{
                                            ...StyleSheet.flatten(customerDataStyles.customLink),
                                            color: this.props.theme.colors.text,
                                        }}
                                        to="/datenschutz"
                                    >
                                        hier
                                    </Link>
                                )}
                                finden
                            </Text>
                        </View>
                    );
                }

                // if (field.type === "printRecipeCheckbox") {
                //     return (
                //         <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                // <Checkbox.Item
                //     label="Rezept drucken"
                //     status={props.values[field.id] ? "checked" : "unchecked"}
                //     onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                //     uncheckedColor={this.props.theme.colors.primary}
                //     color={getContrastColor(this.props.theme.colors.primary)}
                //     style={[
                //         customerDataStyles.checkbox,
                //         {
                //             backgroundColor: props.values[field.id]
                //                 ? this.props.theme.colors.primary
                //                 : this.props.theme.colors.surface,
                //             borderColor: this.props.theme.colors.primary,
                //         },
                //     ]}
                //     labelStyle={{
                //         color: props.values[field.id]
                //             ? getContrastColor(this.props.theme.colors.primary)
                //             : getContrastColor(this.props.theme.colors.surface),
                //     }}
                //     position="leading"
                //     rippleColor="transparent"
                //     mode="android"
                // />;
                //         </View>
                //     );
                // }

                if (field.id === "title") {
                    const { customer } = this.props.account;
                    const [checked, setChecked] = React.useState("Herr");

                    React.useEffect(() => {
                        if (!props.values[field.id]) {
                            props.setFieldValue(field.id, "Herr");
                        }
                    }, [props.values[field.id]]);

                    return (
                        !customer && (
                            <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                                <Text>Anrede</Text>
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <Pressable
                                        onPress={() => {
                                            props.setFieldValue(field.id, "Herr");
                                            setChecked("Herr");
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                                            <RadioButton.IOS
                                                value="Herr"
                                                status={checked === "Herr" ? "checked" : "unchecked"}
                                                onPress={() => {
                                                    props.setFieldValue(field.id, "Herr");
                                                    setChecked("Herr");
                                                }}
                                            />
                                            <Text>Herr</Text>
                                        </View>
                                    </Pressable>
                                    <Pressable
                                        onPress={() => {
                                            props.setFieldValue(field.id, "Frau");
                                            setChecked("Frau");
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                                            <RadioButton.IOS
                                                value="Frau"
                                                status={checked === "Frau" ? "checked" : "unchecked"}
                                                onPress={() => {
                                                    props.setFieldValue(field.id, "Frau");
                                                    setChecked("Frau");
                                                }}
                                            />
                                            <Text>Frau</Text>
                                        </View>
                                    </Pressable>
                                    <Pressable
                                        onPress={() => {
                                            props.setFieldValue(field.id, "Divers");
                                            setChecked("Divers");
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                                            <RadioButton.IOS
                                                value="Divers"
                                                status={checked === "Divers" ? "checked" : "unchecked"}
                                                onPress={() => {
                                                    props.setFieldValue(field.id, "Divers");
                                                    setChecked("Divers");
                                                }}
                                            />
                                            <Text>Divers</Text>
                                        </View>
                                    </Pressable>
                                </View>
                                {error && (
                                    <View>
                                        <Text style={styles.pickerError}>
                                            {props.errors[field.id] ? props.errors[field.id] : ""}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        )
                    );
                }
                if (field.type === "useDifferentBillingAddress") {
                    return (
                        <View key={field.id} id={field.id} style={[customerDataStyles.fieldRow, { marginTop: 15 }]}>
                            <Checkbox.Item
                                label="Abweichende Rechnungsadresse"
                                status={props.values[field.id] ? "checked" : "unchecked"}
                                onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                                uncheckedColor={this.props.theme.colors.primary}
                                color={getContrastColor(this.props.theme.colors.primary)}
                                style={[
                                    customerDataStyles.checkbox,
                                    {
                                        backgroundColor: props.values[field.id]
                                            ? this.props.theme.colors.primary
                                            : this.props.theme.colors.surface,
                                        borderColor: this.props.theme.colors.primary,
                                    },
                                ]}
                                labelStyle={{
                                    color: props.values[field.id]
                                        ? getContrastColor(this.props.theme.colors.primary)
                                        : getContrastColor(this.props.theme.colors.surface),
                                }}
                                position="leading"
                                rippleColor="transparent"
                                mode="android"
                            />
                        </View>
                    );
                }

                if (field.type === "selectAddress") {
                    const { customer } = this.props.account;
                    const { theme } = this.props;
                    const { windowWidth } = this.state;
                    var tempAddresses = [];
                    if (this.state.addresses.length > 0) {
                        tempAddresses = this.state.addresses;
                    }
                    const [selectedAddress, setSelectedAddress] = React.useState(0);

                    const sortedDeliveryAddresses = [...tempAddresses].sort((a, b) => {
                        if (a.isDefaultDeliveryAddress) return -1;
                        if (b.isDefaultDeliveryAddress) return 1;
                        return a.addressId - b.addressId;
                    });

                    if (
                        sortedDeliveryAddresses &&
                        sortedDeliveryAddresses.length > 0 &&
                        !props.values["deliveryAddress"]
                    ) {
                        props.setFieldValue("deliveryAddress", JSON.stringify(sortedDeliveryAddresses[0]));
                    }

                    const sortedBillingAddresses = [...tempAddresses].sort((a, b) => {
                        if (a.isDefaultBillingAddress) return -1;
                        if (b.isDefaultBillingAddress) return 1;
                        return a.addressId - b.addressId;
                    });

                    if (
                        sortedBillingAddresses &&
                        sortedBillingAddresses.length > 0 &&
                        !props.values["billingAddress"]
                    ) {
                        props.setFieldValue("billingAddress", JSON.stringify(sortedBillingAddresses[0]));
                    }

                    return (
                        customer &&
                        (field.id === "deliveryAddress" || props.values["useDifferentBillingAddress"]) && (
                            <View key={field.id} id={field.id} style={{ width: "100%" }}>
                                <Text style={{ width: "100%", marginBottom: 15, marginLeft: 10, fontWeight: "bold" }}>
                                    {field.id === "deliveryAddress" ? "Lieferadresse" : "Rechnungsadresse"}
                                </Text>
                                <View
                                    style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%" }}
                                >
                                    {field.id === "deliveryAddress" &&
                                        sortedDeliveryAddresses &&
                                        sortedDeliveryAddresses.map((address, index) => {
                                            return (
                                                <Card
                                                    onPress={() => {
                                                        props.setFieldValue(field.id, JSON.stringify(address));
                                                        setSelectedAddress(index);
                                                    }}
                                                    style={{
                                                        backgroundColor:
                                                            selectedAddress === index
                                                                ? theme.colors.primary
                                                                : theme.colors.surface,
                                                        borderColor: theme.colors.primary,
                                                        borderWidth: "1px",
                                                        borderStyle: "solid",
                                                        width:
                                                            windowWidth <= 768
                                                                ? "calc(100% - 10px)"
                                                                : "calc(33% - 10px)",
                                                        display: "flex",
                                                        margin: 5,
                                                        cursor: "pointer",
                                                    }}
                                                    key={index}
                                                >
                                                    <Card.Title
                                                        titleStyle={{
                                                            color:
                                                                selectedAddress === index
                                                                    ? getContrastColor(theme.colors.primary)
                                                                    : theme.colors.onSurface,
                                                        }}
                                                        title={address.firstName + " " + address.lastName}
                                                    />
                                                    <Card.Content>
                                                        <Text
                                                            style={{
                                                                color:
                                                                    selectedAddress === index
                                                                        ? getContrastColor(theme.colors.primary)
                                                                        : theme.colors.onSurface,
                                                            }}
                                                        >
                                                            {address.street +
                                                                " " +
                                                                address.housenumber +
                                                                "\n" +
                                                                address.zip +
                                                                " " +
                                                                address.city +
                                                                "\n" +
                                                                address.country +
                                                                "\n\n" +
                                                                (address.company ? address.company : "") +
                                                                "\n" +
                                                                (address.addressDetails ? address.addressDetails : "")}
                                                        </Text>
                                                    </Card.Content>
                                                </Card>
                                            );
                                        })}
                                    {field.id === "billingAddress" &&
                                        sortedBillingAddresses &&
                                        sortedBillingAddresses.map((address, index) => {
                                            return (
                                                <Card
                                                    onPress={() => {
                                                        props.setFieldValue(field.id, JSON.stringify(address));
                                                        setSelectedAddress(index);
                                                    }}
                                                    style={{
                                                        backgroundColor:
                                                            selectedAddress === index
                                                                ? theme.colors.primary
                                                                : theme.colors.surface,
                                                        borderColor: theme.colors.primary,
                                                        borderWidth: "1px",
                                                        borderStyle: "solid",
                                                        width:
                                                            windowWidth <= 768
                                                                ? "calc(100% - 10px)"
                                                                : "calc(33% - 10px)",
                                                        display: "flex",
                                                        margin: 5,
                                                        cursor: "pointer",
                                                    }}
                                                    key={index}
                                                >
                                                    <Card.Title
                                                        titleStyle={{
                                                            color:
                                                                selectedAddress === index
                                                                    ? getContrastColor(theme.colors.primary)
                                                                    : theme.colors.onSurface,
                                                        }}
                                                        title={address.firstName + " " + address.lastName}
                                                    />
                                                    <Card.Content>
                                                        <Text
                                                            style={{
                                                                color:
                                                                    selectedAddress === index
                                                                        ? getContrastColor(theme.colors.primary)
                                                                        : theme.colors.onSurface,
                                                            }}
                                                        >
                                                            {address.street +
                                                                " " +
                                                                address.housenumber +
                                                                "\n" +
                                                                address.zip +
                                                                " " +
                                                                address.city +
                                                                "\n" +
                                                                address.country +
                                                                "\n\n" +
                                                                (address.company ? address.company : "") +
                                                                "\n" +
                                                                (address.addressDetails ? address.addressDetails : "")}
                                                        </Text>
                                                    </Card.Content>
                                                </Card>
                                            );
                                        })}
                                    <CustomerCheckoutAddAddress
                                        update={this.refreshAddresses}
                                        keycloak={this.props.keycloak}
                                    />
                                </View>
                            </View>
                        )
                    );
                }
            }

            return "";
        });
    }

    async buildInitialValues() {
        const initialValues = {};
        const { customer } = this.props.account;

        await this.props.settings.customerData.fields.forEach((field) => {
            let initialValue = field.value || "";
            if (field.id === "printBill" || field.id === "printRecipe" || field.id === "acceptTerms") {
                initialValue = false;
            } else if (field.id === "billingAddress") {
                if (customer && this.state.addresses) {
                    let address = this.state.addresses.filter((address) => address.isDefaultBillingAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else if (field.id === "country") {
                const defaultCountry = this.props.settings.store.countries
                    ? this.props.settings.store.countries.find((country) => country.isDefault)
                    : null;
                initialValue = defaultCountry ? defaultCountry.countryId : initialValue;
            } else if (field.id === "billingCountry") {
                const defaultCountry = this.props.settings.store.countries
                    ? this.props.settings.store.countries.find((country) => country.isDefault)
                    : null;
                initialValue = defaultCountry ? defaultCountry.countryId : initialValue;
            } else if (field.id === "deliveryAddress") {
                if (customer && this.state.addresses) {
                    let address = this.state.addresses.filter((address) => address.isDefaultDeliveryAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else if (field.id === "useDifferentBillingAddress") {
                if (
                    customer &&
                    this.state.addresses &&
                    this.state.addresses.find((address) => address.isDefaultBillingAddress)?.addressId !==
                        this.state.addresses.find((address) => address.isDefaultDeliveryAddress)?.addressId
                ) {
                    initialValue = true;
                }
            } else {
                if (this.props.account.customer) {
                    initialValue = this.props.account.customer[field.id]
                        ? this.props.account.customer[field.id]
                        : initialValue;
                }
            }

            initialValues[field.id] = field.value || initialValue;
        });

        if (this.props.account.customer) {
            if (this.customerDataForm.current) {
                this.customerDataForm.current.setValues(initialValues, false);
            }
        }

        initialValues.paymentMethod = this.props.settings.store.availablePaymentTypes[0]?.identifier;

        return initialValues;
    }

    render() {
        const { open, theme, hideCustomerData, mobile, settings } = this.props;
        const { windowWidth, addresses, showMissingAddressPopup, showAddAddressPopup, additionalAddressToEdit, edit } =
            this.state;
        const availablePaymentTypes = settings.store.availablePaymentTypes || [];
        const { customer } = this.props.account;
        const isLoggedIn = this.props.account.customer !== undefined;
        const initialValues = {
            ...this.buildInitialValues(),
            paymentMethod: availablePaymentTypes[0]?.identifier,
            acceptTerms: false,
            country:
                this.buildInitialValues().country ||
                (this.props.settings.store.countries
                    ? this.props.settings.store.countries.find((country) => country.isDefault)?.countryId
                    : "") ||
                "",
        };

        const yupSchema = settings.customerData.fields.reduce((schema, fieldConfig) => {
            return createYupSchema(schema, fieldConfig, isLoggedIn);
        }, {});
        const validateSchema = yup.object().shape(yupSchema);
        return (
            <Portal>
                <Dialog
                    visible={open}
                    onDismiss={hideCustomerData}
                    style={[
                        styles.addressDialogContainer,
                        {
                            backgroundColor: theme.colors.surface,
                            left: windowWidth <= desktopBreakpoint ? "calc(50% - 45vw)" : "calc(50% - 25vw)",
                            width: windowWidth <= desktopBreakpoint ? "90vw" : "50vw",
                        },
                    ]}
                    dismissable={false}
                >
                    <IconButton
                        icon="close"
                        size={24}
                        onPress={hideCustomerData}
                        style={[styles.dialogCloseButton, { backgroundColor: theme.colors.primary }]}
                        iconColor={getContrastColor(theme.colors.primary)}
                        rippleColor="transparent"
                    />
                    <Dialog.Title style={styles.dialogHeadline}>Bestellung aufgeben</Dialog.Title>
                    <Dialog.ScrollArea
                        style={{
                            borderColor: theme.colors.surface,
                        }}
                    >
                        <ScrollView style={customerDataStyles.container}>
                            <Dialog.Content
                                style={{
                                    backgroundColor: theme.colors.surface,
                                    padding: 0,
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    paddingHorizontal: 0,
                                }}
                            >
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validateSchema}
                                    onSubmit={(values) => {
                                        const selectedPaymentType = availablePaymentTypes.find(
                                            (type) => type.identifier === values.paymentMethod
                                        );

                                        if (!selectedPaymentType) {
                                            console.error("Invalid payment type", error);
                                            return;
                                        }

                                        const paymentTypesId = selectedPaymentType?.id;

                                        const payload = {
                                            ...values,
                                            billingAddress: values.useDifferentBillingAddress
                                                ? values.billingAddress
                                                : values.deliveryAddress,
                                        };
                                        this.props.handleSubmit(payload, values.paymentMethod, paymentTypesId);
                                    }}
                                    innerRef={this.customerDataForm}
                                >
                                    {(props) => (
                                        <View style={customerDataStyles.fieldsWrapper}>
                                            <Title style={{ fontFamily: "regular" }}>Kundendaten</Title>
                                            {this.formElements(props)}
                                            <View style={{ width: "100%" }}></View>
                                            <View
                                                style={
                                                    windowWidth <= desktopBreakpoint
                                                        ? customerDataStyles.containerFullRow
                                                        : customerDataStyles.containerSplitRow
                                                }
                                            >
                                                <View style={{ width: "100%" }}>
                                                    <View style={{ paddingBottom: 10 }}>
                                                        <Text style={customerDataStyles.headlineText}>
                                                            Bitte wählen Sie eine Zahlungsart
                                                        </Text>
                                                        <Picker
                                                            id="paymentMethod"
                                                            title="Bitte wählen Sie eine Zahlungsart"
                                                            // selectedValue={props.values.paymentMethod}
                                                            selectedValue={props.values.paymentMethod}
                                                            onValueChange={(value) => {
                                                                props.setFieldValue("paymentMethod", value);
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            style={styles.picker}
                                                        >
                                                            {availablePaymentTypes.map(({ name, identifier }) => (
                                                                <Picker.Item
                                                                    key={name}
                                                                    label={name}
                                                                    value={identifier}
                                                                />
                                                            ))}
                                                        </Picker>
                                                        {props.errors.paymentMethod && (
                                                            <Text
                                                                style={[
                                                                    customerDataStyles.helperText,
                                                                    { color: this.props.theme.colors.error },
                                                                ]}
                                                            >
                                                                {props.errors.paymentMethod}
                                                            </Text>
                                                        )}
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    )}
                                </Formik>
                            </Dialog.Content>
                        </ScrollView>
                        <Dialog.Actions style={{ paddingBottom: 0, paddingTop: 15 }}>
                            <View
                                style={[
                                    windowWidth <= desktopBreakpoint
                                        ? customerDataStyles.containerFullRow
                                        : customerDataStyles.containerSplitRow,
                                    {
                                        paddingBottom: 0,
                                    },
                                ]}
                            >
                                <Button
                                    textColor={getContrastColor(theme.colors.primary)}
                                    style={{
                                        backgroundColor: theme.colors.error,
                                        marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                                        marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                                        width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                                    }}
                                    onPress={hideCustomerData}
                                    uppercase={false}
                                >
                                    Abbrechen
                                </Button>

                                <Button
                                    textColor={getContrastColor(theme.colors.primary)}
                                    style={{
                                        backgroundColor: theme.colors.primary,
                                        marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                                        marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                                        width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                                    }}
                                    onPress={() => {
                                        if (this.props.account.customer && this.state.addresses.length < 1) {
                                            this.toggleMissingAddressPopup();
                                        } else {
                                            this.customerDataForm.current.submitForm();
                                        }
                                    }}
                                    uppercase={false}
                                >
                                    {this.props.submitButtonName}
                                </Button>
                            </View>
                        </Dialog.Actions>
                    </Dialog.ScrollArea>
                    {showMissingAddressPopup && (
                        <MissingAddressPopup
                            visible={showMissingAddressPopup}
                            toggleOverlay={this.toggleMissingAddressPopup}
                            onAddAddress={this.openAddAddressPopup}
                            windowHeight={this.state.windowHeight}
                        />
                    )}
                    {showAddAddressPopup && (
                        <AddAddressPopup
                            visible={showAddAddressPopup}
                            togglePopup={this.toggleAddAddressPopup}
                            numberOfAddressesSaved={customer ? customer.addresses.length : 0}
                            addressIndex={0}
                            addressToEdit={additionalAddressToEdit}
                            edit={edit}
                            theme={theme}
                            update={this.refreshAddresses}
                            keycloak={this.props.keycloak}
                        />
                    )}
                </Dialog>
            </Portal>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, zipCode } = state;
    return { settings, account, zipCode };
}

export default connect(mapStateToProps)(withTheme(CustomerData));
