import React, { useEffect, useState, useRef } from "react";
import { ActivityIndicator, View } from "react-native";
import { Card, Divider, Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { desktopBreakpoint } from "../../shared/helpers";
import { removeAllOrderItems } from "../../store/actions/cartActions";
import { getOrderStatusByUid } from "../../store/actions/orderActions";
import { setOrderStatus } from "../../store/actions/orderStatusActions";
import { store } from "../../store/store";
import ArticleList from "./ArticleList";
import CustomerInformations from "./CustomerInformations";
import OrderInformations from "./OrderInformations";
import { orderTrackingStyles } from "./OrderTrackingStyles";

const OrderTracking = ({ orderStatus, theme, match, windowWidth }) => {
    const [loading, setLoading] = useState(false);
    const idRef = useRef(match.params.id);
    const isFetching = useRef(false);
    const previousOrderState = useRef(null);

    useEffect(() => {
        const fetchOrderStatus = async () => {
            setLoading(true);
            store.dispatch(setOrderStatus(undefined));
            const result = await store.dispatch(getOrderStatusByUid(idRef.current));
            setLoading(false);
            store.dispatch(removeAllOrderItems());
            store.dispatch(setOrderStatus(result));
            previousOrderState.current = result?.orderStateString;
        };

        fetchOrderStatus();

        const interval = setInterval(async () => {
            if (
                !isFetching.current &&
                (!orderStatus?.data || orderStatus.data.orderStateString !== "Abgeschlossen") &&
                previousOrderState.current !== orderStatus?.data?.orderStateString
            ) {
                isFetching.current = true;
                const result = await store.dispatch(getOrderStatusByUid(idRef.current));
                store.dispatch(setOrderStatus(result));
                previousOrderState.current = result?.orderStateString;
                isFetching.current = false;
            }
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const id = orderStatus?.data ? orderStatus.data.uid : null;
    const mobile = windowWidth < desktopBreakpoint;

    return (
        <View
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <View style={{ width: mobile ? 350 : 800 }}>
                {loading && (
                    <ActivityIndicator
                        size={"large"}
                        style={{ marginTop: 100 }}
                        animated
                        color={theme.colors.primary}
                    />
                )}
                {!loading && (!id || orderStatus.data === false) && (
                    <Text variant="headlineSmall" style={{ marginTop: 50 }}>
                        Keine passende Bestellung gefunden
                    </Text>
                )}
                {!loading && id && orderStatus.data !== false && (
                    <View>
                        <Text variant="headlineSmall" style={{ marginTop: 50, textAlign: "center" }}>
                            Vielen Dank für Ihre Bestellung - wir kümmern uns schnellstmöglich um den Versand.
                        </Text>
                        <Card
                            style={{
                                marginVertical: mobile ? 20 : 40,
                                marginBottom: 70,
                                borderRadius: 13,
                            }}
                        >
                            <Card.Title
                                style={{ marginTop: 16 }}
                                title={
                                    <Text variant="headlineSmall">
                                        Ihre Bestellung (Nr. {orderStatus.data.orderNumber})
                                    </Text>
                                }
                            />
                            <Card.Content style={{ marginBottom: 16 }}>
                                <View>
                                    <OrderInformations data={orderStatus.data} />
                                    <Divider style={{ marginVertical: 20 }} />
                                    <CustomerInformations customer={orderStatus.data.customer} />
                                    <Divider style={{ marginVertical: 20 }} />
                                    <ArticleList cartJson={orderStatus.data.cartJson} />
                                </View>
                            </Card.Content>
                        </Card>
                    </View>
                )}
            </View>
        </View>
    );
};

function mapStateToProps(state) {
    const { orderStatus } = state;
    return { orderStatus };
}

export default connect(mapStateToProps)(withRouter(withTheme(OrderTracking)));
