import Color from "color";
import { DefaultTheme } from "react-native-paper";

import Config from "../../../Config";
import { loadFont } from "../../helpers/fontLoader/fontLoader";
import { handleResponse } from "../../helpers/requestHelpers";
import { store } from "../store";
import { fetchWithSessionHandling } from "../../helpers/sessionHandling/fetchWithSessionHandling";

const config = new Config();

export const SET_SETTINGS = "SET_SETTINGS";

export function setSettings(settings) {
    return {
        type: SET_SETTINGS,
        store: settings,
    };
}

export function setMetaTag(metaTag) {
    const meta = document.createElement("meta");
    meta.name = "google-site-verification";
    meta.content = metaTag;
    document.getElementsByTagName("head")[0].appendChild(meta);
}

export function getSettings() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/getShopSettings`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                if (json) {
                    // API returns the value as a string, so this necessary conversion to a Boolean
                    if (json.dynamicMinOrderCharge !== undefined) {
                        json.dynamicMinOrderCharge = json.dynamicMinOrderCharge.toLowerCase() === "true";
                    }

                    store.dispatch(setSettings(json));
                    if (json.metaTag) {
                        setMetaTag(json.metaTag);
                    }
                }
                return store.dispatch(getActiveShopTheme());
            })
            .then((theme) => {
                return theme;
            });
    };
}

export function getActiveShopTheme() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/getActiveShopTheme`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                const theme = {
                    ...DefaultTheme,
                    title: json.title,
                    logoUrl: json.LogoUrl,
                    faviconUrl: json.faviconUrl,
                    updatedDate: json.updatedDate, //needed for font cache busting
                    // roundness: 1,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: json.primary,
                        accent: json.accent,
                        background: json.background,
                        surface: json.surface,
                        error: json.error,
                        text: json.text,
                        disabled: json.disabled,
                        placeholder: json.placeholder,
                        backdrop: json.backdrop,
                        notification: json.notification,
                        onSurface: json.onSurface,
                    },
                    fonts: {
                        ...DefaultTheme.fonts,
                        default: {
                            ...DefaultTheme.fonts.default,
                            fontFamily: "regular",
                            fontWeight: json.regularFontWeight,
                        },
                        bodySmall: {
                            ...DefaultTheme.fonts.bodySmall,
                            fontFamily: "light",
                            fontWeight: json.mediumFontWeight,
                        },
                        bodyMedium: {
                            ...DefaultTheme.fonts.bodyMedium,
                            fontFamily: "medium",
                            fontWeight: json.mediumFontWeight,
                        },
                        bodyLarge: {
                            ...DefaultTheme.fonts.bodyLarge,
                            fontFamily: "regular",
                            fontWeight: json.mediumFontWeight,
                        },
                        labelSmall: {
                            ...DefaultTheme.fonts.labelSmall,
                            fontFamily: "light",
                            fontWeight: json.lightFontWeight,
                        },
                        labelMedium: {
                            ...DefaultTheme.fonts.labelMedium,
                            fontFamily: "medium",
                            fontWeight: json.mediumFontWeight,
                        },
                        labelLarge: {
                            ...DefaultTheme.fonts.labelLarge,
                            fontFamily: "regular",
                            fontWeight: json.mediumFontWeight,
                        },
                        titleSmall: {
                            ...DefaultTheme.fonts.titleSmall,
                            fontFamily: "light",
                            fontWeight: json.lightFontWeight,
                        },
                        titleMedium: {
                            ...DefaultTheme.fonts.titleMedium,
                            fontFamily: "medium",
                            fontWeight: json.mediumFontWeight,
                        },
                        titleLarge: {
                            ...DefaultTheme.fonts.titleLarge,
                            fontFamily: "regular",
                            fontWeight: json.mediumFontWeight,
                        },
                        headlineSmall: {
                            ...DefaultTheme.fonts.headlineSmall,
                            fontFamily: "light",
                            fontWeight: json.lightFontWeight,
                        },
                        headlineMedium: {
                            ...DefaultTheme.fonts.headlineMedium,
                            fontFamily: "medium",
                            fontWeight: json.mediumFontWeight,
                        },
                        headlineLarge: {
                            ...DefaultTheme.fonts.headlineLarge,
                            fontFamily: "regular",
                            fontWeight: json.mediumFontWeight,
                        },
                        displaySmall: {
                            ...DefaultTheme.fonts.displaySmall,
                            fontFamily: "light",
                            fontWeight: json.lightFontWeight,
                        },
                        displayMedium: {
                            ...DefaultTheme.fonts.displayMedium,
                            fontFamily: "medium",
                            fontWeight: json.mediumFontWeight,
                        },
                        displayLarge: {
                            ...DefaultTheme.fonts.displayLarge,
                            fontFamily: "regular",
                            fontWeight: json.mediumFontWeight,
                        },
                        regular: {
                            ...DefaultTheme.fonts.regular,
                            fontFamily: "regular",
                            fontWeight: json.regularFontWeight,
                        },
                        medium: {
                            ...DefaultTheme.fonts.medium,
                            fontFamily: "medium",
                            fontWeight: json.mediumFontWeight,
                        },
                        light: {
                            ...DefaultTheme.fonts.light,
                            fontFamily: "light",
                            fontWeight: json.lightFontWeight,
                        },
                        thin: {
                            ...DefaultTheme.fonts.thin,
                            fontFamily: "thin",
                            fontWeight: json.thinFontWeight,
                        },
                    },
                    customCSS: json.customCSS,
                    regularFontPath: json.regularFontPath,
                    mediumFontPath: json.mediumFontPath,
                    lightFontPath: json.lightFontPath,
                    thinFontPath: json.thinFontPath,
                };
                loadFont(json);
                const styleElement = document.createElement("style");
                styleElement.textContent = json.customCSS;
                document.head.appendChild(styleElement);

                store.dispatch(set_theme(theme));

                return theme;
            });
    };
}

export const SET_THEME = "SET_THEME";

export function set_theme(theme) {
    if (theme) {
        // if (document.getElementById("customCSS")) {
        //     document.getElementById("customCSS").remove();
        // }

        // const customStyle = document.createElement("style");
        // customStyle.setAttribute("id", "customCSS");
        // document.head.appendChild(customStyle);
        // customStyle.innerHTML = theme.customCSS ? theme.customCSS : "";

        return {
            type: SET_THEME,
            theme: theme,
        };
    }

    return { type: SET_THEME };
}

export const SET_SEARCHTEXT = "SET_SEARCHTEXT";

export function setSearchText(searchText) {
    return {
        type: SET_SEARCHTEXT,
        searchText,
    };
}

export function getDataProtectionPolicy() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/getDataProtectionPolicy/${config.licenseNumber}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function getImprint() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/getImprint/${config.licenseNumber}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function getTermsAndConditions() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/getTermsAndConditions/${config.licenseNumber}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function getWithdrawalNotice() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/getWithdrawalNotice/${config.licenseNumber}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                return json;
            });
    };
}
