import { Formik } from "formik";
import React, { Component } from "react";
import { Button, Card, Text, IconButton } from "react-native-paper";
import { View } from "react-native";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Picker } from "@react-native-picker/picker";

import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import { updateOnlineShopCustomer } from "../../store/actions/accountActions";
import { store } from "../../store/store";
import CustomInputField from "../../helpers/inputField/CustomInputField";
import { desktopBreakpoint, getContrastColor } from "../../shared/helpers";
import * as yup from "yup";
import { styles } from "../../shared/styles";
import ToastMessage from "../../helpers/toastMessage/ToastMessage";
import CustomerNavigation from "./CustomerNavigation";

const yupObject = yup.object().shape({
    phoneNumbers: yup.array().of(
        yup.object().shape({
            phonenumber: yup.string().matches(/^\d{7,}$/, "Mindestens 7 Ziffern lang sein"),
        })
    ),
});

class CustomerProfile extends Component {
    constructor(props) {
        super(props);

        const customer = this.props.account.customer;

        this.state = {
            phoneNumbers: customer.phonenumbers
                .filter((p) => !p.deleted)
                .map((phone, index) => ({
                    ...phone,
                    tempId: index,
                })),
            redirectToOrderDetails: false,
        };
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const redirectToOrderDetails = searchParams.get("redirectToOrderDetails") === "true";

        this.setState({
            redirectToOrderDetails,
        });
    }

    componentDidUpdate(prevProps) {
        const { search } = this.props.location;
        const prevSearch = prevProps.location.search;

        if (search !== prevSearch) {
            const searchParams = new URLSearchParams(search);
            const redirectToOrderDetails = searchParams.get("redirectToOrderDetails") === "true";

            if (redirectToOrderDetails !== this.state.redirectToOrderDetails) {
                this.setState({
                    redirectToOrderDetails,
                });
            }
        }
    }

    handleSubmitForm = (values, { setFieldValue }) => {
        const { redirectToOrderDetails } = this.state;
        const { theme } = this.props.settings;

        const isProfileComplete = this.getMissingInfoText(values) === null;

        toast.show(
            <ToastMessage
                message={`Änderungen werden gespeichert`}
                icon="information-circle-outline"
                backgroundColor={theme.colors.surface}
                style={{ zIndex: 0 }}
                textColor={getContrastColor(theme.colors.surface)}
            />,
            {
                type: "success",
                placement: "top",
                duration: 5000,
                offset: 1000,
                offsetTop: 3000,
                animationType: "slide-in",
                style: { backgroundColor: "transparent", pointerEvents: "none !important" },
            }
        );

        const updatedPhoneNumbers = values.phoneNumbers
            .filter((phone) => phone.phonenumber.trim().length > 0)
            .map((phone) => ({
                phoneNumbersId: phone.new ? undefined : phone.phoneNumbersId,
                phonenumber: phone.phonenumber,
                new: phone.new || false,
                deleted: phone.deleted || false,
            }));

        store
            .dispatch(
                updateOnlineShopCustomer(
                    values.title,
                    values.firstName,
                    values.familyName,
                    updatedPhoneNumbers,
                    this.props.keycloak
                )
            )
            .then((response) => {
                toast.show(
                    <ToastMessage
                        message={`Änderungen wurden erfolgreich gespeichert`}
                        icon="checkmark-circle-outline"
                        backgroundColor={theme.colors.surface}
                        style={{ zIndex: 0 }}
                        textColor={getContrastColor(theme.colors.surface)}
                    />,
                    {
                        type: "success",
                        placement: "top",
                        duration: 5000,
                        offset: 1000,
                        offsetTop: 3000,
                        animationType: "slide-in",
                        style: {
                            backgroundColor: "transparent",
                            pointerEvents: "none !important",
                        },
                    }
                );
                this.props.update();

                const newPhoneNumbers = updatedPhoneNumbers.filter((phone) => !phone.deleted);
                setFieldValue("phoneNumbers", newPhoneNumbers);
                this.setState({ phoneNumbers: newPhoneNumbers });

                if (redirectToOrderDetails && isProfileComplete) {
                    this.props.toggleCustomerData();
                }
            });
    };

    handleAddPhoneNumber = (values, setFieldValue) => {
        const newPhoneNumbers = [
            ...values.phoneNumbers,
            {
                phoneNumbersId: undefined,
                phonenumber: "",
                new: true,
                deleted: false,
                tempId: Date.now(),
            },
        ];
        setFieldValue("phoneNumbers", newPhoneNumbers);
    };

    handlePhoneNumberChange = (index, value, values, setFieldValue) => {
        const newPhoneNumbers = [...values.phoneNumbers];
        newPhoneNumbers[index].phonenumber = value;
        setFieldValue("phoneNumbers", newPhoneNumbers);
    };

    handleDeletePhoneNumber = (tempId, values, setFieldValue) => {
        const newPhoneNumbers = values.phoneNumbers.map((phone) =>
            phone.tempId === tempId ? { ...phone, deleted: true } : phone
        );
        setFieldValue("phoneNumbers", newPhoneNumbers);
    };

    getMissingInfoText = (values) => {
        let missingInfo = [];

        if (!values.title) {
            missingInfo.push("Ihre Anrede");
        }
        if (!values.firstName) {
            missingInfo.push("Ihren Vorname");
        }
        if (!values.familyName) {
            missingInfo.push("Ihren Nachname");
        }
        if (values.phoneNumbers.every((phone) => !phone.phonenumber)) {
            missingInfo.push("Ihre Telefonnummer");
        }

        if (missingInfo.length === 0) {
            return null;
        }

        return `Wenn Sie eine Bestellung tätigen möchten vervollständigen Sie bitte Ihr Profil.\nFolgende Angaben fehlen aktuell: \n \n* ${missingInfo.join(
            "\n* "
        )}.`;
    };

    render() {
        const customer = this.props.account.customer;
        const { theme } = this.props.settings;
        const { windowWidth } = this.props;
        const isDesktop = windowWidth >= desktopBreakpoint;

        return (
            <View style={[isDesktop ? styles.desktopContainerWrapper : null, { padding: !isDesktop ? 20 : null }]}>
                <View>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <CustomIconButton
                            icon="arrow-left"
                            tooltip="Zurück"
                            position="right"
                            onPress={() => this.props.history.push("/")}
                        ></CustomIconButton>
                        <Text style={{ marginTop: 17 }}>Zurück zur Startseite</Text>
                    </View>
                    <View
                        nativeID="customer-area"
                        style={[
                            styles.customerArea,
                            isDesktop ? styles.customerAreaDesktop : styles.customerAreaMobile,
                        ]}
                    >
                        <View
                            nativeID="customer-navigation"
                            style={[
                                styles.customerNavigation,
                                isDesktop ? styles.customerNavigationDesktop : styles.customerNavigationMobile,
                            ]}
                        >
                            <CustomerNavigation windowWidth={windowWidth} />
                        </View>
                        <View
                            nativeID="customer-content"
                            style={[
                                styles.customerContent,
                                isDesktop ? styles.customerContentDesktop : styles.customerContentMobile,
                            ]}
                        >
                            <View
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Card style={{ width: 350, borderRadius: 13, marginBottom: 50 }}>
                                    <Formik
                                        initialValues={{
                                            title: customer.title || "",
                                            firstName: customer.firstName,
                                            familyName: customer.familyName,
                                            email: customer.email,
                                            phoneNumbers: this.state.phoneNumbers,
                                        }}
                                        validationSchema={yupObject}
                                        onSubmit={this.handleSubmitForm}
                                    >
                                        {({
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            values,
                                            errors,
                                            setFieldValue,
                                        }) => (
                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: 30,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        styles.profilEditSelectInfoText,
                                                        { color: "red", marginLeft: 20, textAlign: "start" },
                                                    ]}
                                                >
                                                    {this.getMissingInfoText(values)}
                                                </Text>
                                                <Text style={styles.profilEditSelectLabel}>Anrede</Text>
                                                <Picker
                                                    selectedValue={values.title}
                                                    onValueChange={(itemValue) => setFieldValue("title", itemValue)}
                                                    style={[
                                                        styles.profilEditSelect,
                                                        {
                                                            backgroundColor: theme.colors.surface,
                                                            borderColor: getContrastColor(theme.colors.surface),
                                                            borderWidth: 1,
                                                            color: getContrastColor(theme.colors.surface),
                                                            fontFamily: theme.fonts.regular.fontFamily,
                                                            fontSize: 17,
                                                            marginBottom: 10,
                                                            paddingVertical: 15,
                                                            width: 230,
                                                        },
                                                    ]}
                                                >
                                                    <Picker.Item label="- bitte auswählen -" value="" />
                                                    <Picker.Item label="Herr" value="Herr" />
                                                    <Picker.Item label="Frau" value="Frau" />
                                                    <Picker.Item label="Divers" value="Divers" />
                                                </Picker>

                                                <CustomInputField
                                                    label="Vorname"
                                                    onChange={handleChange("firstName")}
                                                    value={values.firstName}
                                                    style={{ backgroundColor: theme.colors.surface, width: 230 }}
                                                />

                                                <CustomInputField
                                                    label="Nachname"
                                                    onChange={handleChange("familyName")}
                                                    value={values.familyName}
                                                    style={{ backgroundColor: theme.colors.surface, width: 230 }}
                                                />

                                                <CustomInputField
                                                    label="E-Mail"
                                                    disabled={true}
                                                    type="email"
                                                    onChange={handleChange("email")}
                                                    value={values.email}
                                                    style={{ width: 230 }}
                                                />

                                                <Text style={{ marginVertical: 10 }}>Telefonnummern</Text>
                                                {values.phoneNumbers
                                                    .filter((phone) => !phone.deleted)
                                                    .map((phone, index) => (
                                                        <View
                                                            key={phone.tempId || phone.phoneNumbersId || index}
                                                            style={{
                                                                flexDirection: "row",
                                                                alignItems: "left",
                                                                marginVertical: 5,
                                                                width: 230,
                                                            }}
                                                        >
                                                            <View
                                                                key={index}
                                                                style={{
                                                                    flexDirection: "column",
                                                                    alignItems: "left",
                                                                }}
                                                            >
                                                                <CustomInputField
                                                                    label={`Telefonnummer ${index + 1}`}
                                                                    onChange={(text) =>
                                                                        this.handlePhoneNumberChange(
                                                                            index,
                                                                            text,
                                                                            values,
                                                                            setFieldValue
                                                                        )
                                                                    }
                                                                    value={phone.phonenumber}
                                                                    error={
                                                                        errors.phoneNumbers &&
                                                                        errors.phoneNumbers[index]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    helperText={
                                                                        errors.phoneNumbers &&
                                                                        errors.phoneNumbers[index]
                                                                            ? errors.phoneNumbers[index].phonenumber
                                                                            : ""
                                                                    }
                                                                    style={{
                                                                        backgroundColor: theme.colors.surface,
                                                                        width: 200,
                                                                    }}
                                                                />
                                                            </View>
                                                            <IconButton
                                                                icon="delete"
                                                                onPress={() => {
                                                                    this.handleDeletePhoneNumber(
                                                                        phone.tempId,
                                                                        values,
                                                                        setFieldValue
                                                                    );
                                                                }}
                                                            />
                                                        </View>
                                                    ))}
                                                <Button
                                                    mode="contained"
                                                    textColor={getContrastColor(theme.colors.primary)}
                                                    style={{
                                                        backgroundColor: theme.colors.primary,
                                                        textTransform: "none",
                                                        marginTop: 10,
                                                        marginBottom: 0,
                                                        width: 230,
                                                    }}
                                                    onPress={() => this.handleAddPhoneNumber(values, setFieldValue)}
                                                >
                                                    Telefonnummer hinzufügen
                                                </Button>

                                                <Button
                                                    type="submit"
                                                    onPress={handleSubmit}
                                                    textColor={getContrastColor(theme.colors.primary)}
                                                    style={{
                                                        backgroundColor: theme.colors.primary,
                                                        textTransform: "none",
                                                        marginTop: 50,
                                                        marginBottom: 50,
                                                        width: 230,
                                                    }}
                                                    uppercase={false}
                                                >
                                                    Änderungen speichern
                                                </Button>
                                            </View>
                                        )}
                                    </Formik>
                                </Card>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withRouter(CustomerProfile));
