import React from "react";
import { View } from "react-native";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { Text } from "react-native-paper";

export default function CustomerInformations(props) {
    const { customer } = props;
    return (
        <View>
            <View>
                <Text variant="headlineSmall" style={orderTrackingStyles.headline}>
                    Kundendaten
                </Text>
            </View>
            <View style={orderTrackingStyles.text}>
                {customer.deliveryCompany && <Text style={orderTrackingStyles.text}>{customer.deliveryCompany}</Text>}
                <Text style={orderTrackingStyles.text}>
                    {customer.deliveryTitle + " " + customer.deliveryFirstName + " " + customer.deliveryLastName}
                </Text>
                {customer.deliveryAddressDetails && (
                    <Text style={orderTrackingStyles.text}>{customer.deliveryAddressDetails}</Text>
                )}
                <Text style={orderTrackingStyles.text}>
                    {customer.deliveryStreet} {customer.deliveryHousenumber}
                </Text>
                <Text style={orderTrackingStyles.text}>
                    {customer.deliveryZipcode} {customer.deliveryCity}
                </Text>
                <Text style={orderTrackingStyles.text}>{customer.email}</Text>
                {customer.phoneNumbers && <Text style={orderTrackingStyles.text}>{customer.phoneNumbers}</Text>}
                {customer.comment && <Text style={orderTrackingStyles.text}>{customer.comment}</Text>}
            </View>
            {customer.useDifferentBillingAddress && (
                <View style={orderTrackingStyles.billingAddress}>
                    <Text variant="headlineSmall" style={orderTrackingStyles.headline}>
                        Rechnungsadresse
                    </Text>
                    {customer.billingCompany && <Text style={orderTrackingStyles.text}>{customer.billingCompany}</Text>}

                    <Text style={orderTrackingStyles.text}>
                        {customer.billingTitle + " " + customer.billingFirstName + " " + customer.billingLastName}
                    </Text>
                    {customer.billingAddressDetails && (
                        <Text style={orderTrackingStyles.text}>{customer.billingAddressDetails}</Text>
                    )}
                    <Text style={orderTrackingStyles.text}>
                        {customer.billingStreet} {customer.billingHousenumber}
                    </Text>
                    <Text style={orderTrackingStyles.text}>
                        {customer.billingZipcode} {customer.billingCity}
                    </Text>
                </View>
            )}
        </View>
    );
}
